@import url('https://fonts.googleapis.com/css2?family=SUSE:wght@100..800&display=swap');
body {
  margin: 0;
  font-family: "SUSE", sans-serif !important;
  font-optical-sizing: auto;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-image: linear-gradient(to left, rgb(27 20 41), rgb(20 15 35));
}
