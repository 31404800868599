html {
  --section-background-color: linear-gradient(
    to bottom left,
    rgba(17, 16, 16, 0.582),
    rgba(12, 8, 24, 0.904)
  );

  --image-gradient: linear-gradient(
    to bottom left,
    rgba(17, 16, 16, 0.678),
    rgba(12, 10, 22, 0.863)
  );

  --imp-text-color: #c770f0;
}

.purple {
  color: var(--imp-text-color) !important;
  font-weight: 700;
}

button:focus {
  box-shadow: none !important;
}

/* --------- */
/*  Preloader */
/* --------- */
#preloader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999999;
  background-color: #0c0513;
  background-image: url(./assets/pre.svg);
  background-repeat: no-repeat;
  background-position: center;
}

#preloader-none {
  opacity: 0;
}

#no-scroll {
  overflow: hidden;
  height: 100vh;
}

/* --------- */
/*Scrollbar   */
/* --------- */

::-webkit-scrollbar {
  width: 7px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #2d1950;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgba(178, 121, 216, 0.959);
  border-radius: 12px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgba(222, 130, 235, 0.911);
  border-radius: 12px;
}

/* --------- */
/* Navbar Section  */
/* --------- */
.sticky {
  background-color: #1b1a2ea9 !important;
  transition: all 0.3s ease-out 0s !important;
  box-shadow: 0px 0.75rem 0.75rem 0px rgba(9, 5, 29, 0.171) !important;
  backdrop-filter: blur(1rem) !important;
}

.navbar {
  position: fixed !important;
  transition: all 0.3s ease-out 0s !important;
  padding: 0.3rem 2rem !important;
  font-size: 1.2rem !important;
}

.navbar-toggler {
  position: relative !important;
  background-color: transparent !important;
  border-color: transparent !important;
}

.navbar-toggler span {
  display: block !important;
  background-color: #be50f4 !important;
  height: 4px !important;
  width: 27px !important;
  margin-top: 5px !important;
  margin-bottom: 5px !important;
  transform: rotate(0deg) !important;
  left: 0 !important;
  opacity: 1 !important;
}

.navbar-toggler:focus,
.navbar-toggler:active {
  outline: 0 !important;
}

.navbar-toggler span:nth-child(1),
.navbar-toggler span:nth-child(3) {
  transition: transform 0.35s ease-in-out !important;
  transition: transform 0.35s ease-in-out !important;
}

.navbar-toggler:not(.collapsed) span:nth-child(1) {
  position: absolute !important;
  left: 12px !important;
  top: 0.75rem !important;
  transform: rotate(135deg) !important;
  opacity: 0.9 !important;
}

.navbar-toggler:not(.collapsed) span:nth-child(2) {
  height: 12px !important;
  visibility: hidden !important;
  background-color: transparent !important;
}

.navbar-toggler:not(.collapsed) span:nth-child(3) {
  position: absolute !important;
  left: 12px !important;
  top: 0.75rem !important;
  transform: rotate(-135deg) !important;
  opacity: 0.9 !important;
}

@media (max-width: 767px) {
  .navbar {
    padding: 1rem 2rem !important;
    font-size: 1.4rem !important;
    background-color: #181a27 !important;
  }
  .navbar-nav .nav-itrem a::after {
    display: none !important;
  }
}
.navbar-brand {
  color: rgb(250, 250, 250) !important;
  font-weight: 700;
}
.navbar-brand:hover {
  transform: scale(1.05);
  transition: 0.5s;
}

.logo {
  height: 1.4rem !important;
  width: 2.5rem !important;
}

.navbar-nav .nav-link {
  color: white !important;
  padding-right: 1rem !important;
  padding-left: 1rem !important;
}

.nav-link {
  padding: 0.8rem 1rem !important;
}
.nav-link:hover {
  transform: scale(1.05);
  transition: 0.5s;
}

@media (max-width: 767px) {
  .nav-link {
    padding: 0.7rem 1rem !important;
  }
}

.navbar-nav .nav-itrem {
  position: relative;
  margin-left: 1.25rem;
}

.navbar-nav .nav-itrem a {
  font-weight: 400;
  transition: all 0.3s ease-out 0s;
  position: relative;
  z-index: 1;
}

.navbar-nav .nav-itrem a::after {
  content: "";
  position: relative;
  display: block;
  height: 5px;
  width: 0;
  border-radius: 1rem;
  background: #c95bf5;
  bottom: 1px;
  left: 0;
  z-index: -1;
  transition: all 0.3s ease-out 0s;
}

.navbar-nav .nav-itrem a:hover::after {
  width: 100%;
}

/* --------- */
/* Home section */
/* --------- */
.wave {
  animation-name: wave-animation; /* Refers to the name of your @keyframes elrement below */
  animation-duration: 2.1s; /* Change to speed up or slow down */
  animation-iteration-count: infinite; /* Never stop waving :) */
  transform-origin: 70% 70%; /* Pivot around the bottom-left palm */
  display: inline-block;
}

@keyframes wave-animation {
  0% {
    transform: rotate(0deg);
  }
  10% {
    transform: rotate(14deg);
  } /* The following five values can be played with to make the waving more or less extreme */
  20% {
    transform: rotate(-8deg);
  }
  30% {
    transform: rotate(14deg);
  }
  40% {
    transform: rotate(-4deg);
  }
  50% {
    transform: rotate(10deg);
  }
  60% {
    transform: rotate(0deg);
  } /* Reset for the last half to pause */
  100% {
    transform: rotate(0deg);
  }
}
#tsparticles {
  position: fixed !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  width: 100%;
  height: 100%;
}

.home-header {
  padding-top: 5rem !important;
}

.home-section {
  position: relative;
  z-index: 0;
  background-image: var(--image-gradient), url(./assets/home-bg.jpg);
  background-position: bottom;
  background-repeat: no-repeat;
  padding-bottom: 2rem !important;
  padding-top: 2rem !important;
}

.home-content {
  padding: 9rem 0 2rem !important;
  color: whitesmoke;
  text-align: left;
}

.heading {
  font-size: 2.4rem !important;
  padding-left: 3rem !important;
}

.heading-name {
  font-size: 2.5rem !important;
  padding-left: 3rem !important;
}

.main-name {
  color: #cd5ff8;
}

.Typewriter__wrapper {
  font-size: 2.2rem !important;
  color: #be6adf !important;
  font-weight: 600 !important;
}
.Typewriter__cursor {
  font-size: 2.4rem !important;
  color: #b562d6 !important;
}

@media (max-width: 767px) {
  .Typewriter__wrapper {
    font-size: 1.4rem !important;
    font-weight: 500 !important;
    position: absolute !important;
  }
  .Typewriter__cursor {
    display: none !important;
  }
}

.myAvtar {
  justify-content: center !important;
  padding-top: 9rem !important;
}

@media (max-width: 767px) {
  .myAvtar {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }
}

.home-about-section {
  position: relative;
  z-index: 0;
  padding-bottom: 4.5rem !important;
  padding-top: 4.5rem !important;
}
.section-title {
  font-size: 2.4rem !important;
  text-align: center;
}

.home-about-description {
  color: white !important;
  padding-top: 6.25rem !important;
  padding-bottom: 1.25rem !important;
  text-align: center;
}

.home-about-body {
  padding-top: 3rem;
  font-size: 1.2rem !important;
  text-align: left;
}

.home-about-social {
  text-align: center !important;
  padding-top: 1.5rem;
  color: white !important;
}

.home-about-social-links {
  justify-content: center !important;
  padding-top: 1.5rem !important;
  display: inline-block !important;
  position: relative !important;
  padding-inline-start: 0 !important;
}

.home-social-icons {
  position: relative !important;
  display: inline-block !important;
  width: 3rem !important;
  height: 3rem !important;
  text-align: center !important;
  font-size: 1.6rem !important;
  line-height: 2.6rem !important;
  background: rgba(255, 255, 255, 0.972) !important;
  border-radius: 50% !important;
  transition: 0.5s !important;
}

.home-social-icons::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background: #68187a;
  transition: 0.5s;
  transform: scale(0.9);
  z-index: -1;
}

.home-social-icons:hover::before {
  transform: scale(1.1);
  transition: 0.5s;
}

.social-icons {
  display: inline-block !important;
  padding-right: 1rem;
  padding-left: 1rem;
}
.social-icons:hover {
  transform: scale(1.1);
  transition: 0.5s;
}

.icon-colour {
  color: #700c86 !important;
}

/* --------- */
/* Footer */
/* --------- */
.footer {
  background-color: rgb(10, 4, 22);
  bottom: 0 !important;
  padding-top: 0.75rem !important;
  padding-bottom: 0.5rem !important ;
}
.footer-copywright {
  text-align: center !important;
}

.footer-body {
  z-index: 1;
  text-align: center !important;
}

@media (max-width: 767px) {
  .footer-copywright {
    text-align: center !important;
  }

  .footer-body {
    text-align: center !important;
  }
}

.footer h3 {
  font-size: 1rem;
  color: white !important;
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
}
.footer-icons {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
  padding: 0 !important;
}

.blockquote-footer {
  color: #a588c0 !important;
}
/* --------- */
/* Projects */
/* --------- */
.work-section {
  position: relative !important;
  padding-top: 10rem !important;
  padding-bottom: 2rem !important;
  background-image: var(--section-background-color) !important;
  z-index: 0;
}

.work-card {
  padding: 1.5rem !important;
  height: auto !important;
}

.work-card-view {
  color: white !important;
  background-color: #220429 !important;
  opacity: 0.9 !important;
  transition: all 0.5s ease 0s !important;
  height: 100% !important;
}

.btn-primary {
  color: #fff !important;
  background-color: #220429 !important;
  border-color: #220429 !important;
}

.btn-primary:hover {
  color: #fff !important;
  transition: 0.5s;
  background-color: #6d20c5d7 !important;
  border-color: #6d20c5d7 !important;
}
.btn:focus {
  outline: none !important;
  box-shadow: none !important;
}
.work-heading {
  color: white !important;
  font-size: 2.3rem !important;
  font-weight: 500 !important;
  padding-top: 0.75rem !important;
}

/* --------- */
/* About */
/* --------- */

.about-section {
  position: relative !important;
  padding-top: 6rem !important;
  padding-bottom: 2rem !important;
  background-image: var(--section-background-color) !important;
  color: white !important;
  z-index: 0;
}

.tech-icons {
  font-size: 4.5rem !important;
  margin: 1rem !important;
  padding: 0.75rem !important;
  opacity: 0.93 !important;
  border: 1.7px solid rgba(200, 137, 230, 0.637) !important;
  vertical-align: middle !important;
  text-align: center !important;
  border-radius: 5px !important;
  display: table !important;
  box-shadow: 4px 5px 4px 3px rgba(89, 4, 168, 0.137) !important;
  overflow: hidden !important;
  transition: all 0.4s ease 0s !important;
}

@media (max-width: 767px) {
  .tech-icons {
    margin: 0.75rem !important;
  }
}

.tech-icons:hover {
  transform: scale(1.05) !important;
  overflow: hidden !important;
  border: 2.2px solid rgba(197, 115, 230, 0.883) !important;
}
.tech-icon-images {
  padding: 1.25rem !important;
  line-height: 1.6 !important;
}

.quote-card-view {
  border: none !important;
  color: white !important;
  background-color: transparent !important;
}

.about-activity,
.highlights {
  list-style: none !important;
  text-align: left !important;
  padding-left: 1px !important;
}
.highlights li {
  margin: 0.5rem 0;
}
.highlights li svg {
  margin-right: 0.5rem;
  padding-bottom: 0.25rem;
}

@media (max-width: 767px) {
  .about-img {
    padding-top: 0 !important;
  }
}
/* --------- */
/* Resume */
/* --------- */

.resume-section {
  position: relative !important;
  padding-top: 10.75rem !important;
  padding-bottom: 2rem !important;
  background-image: var(--section-background-color) !important;
  color: white !important;
  z-index: 0;
}

.resume {
  padding-top: 3rem;
  padding-bottom: 3rem;
  justify-content: center;
}

.resume-left {
  padding-right: 5rem !important;
}

.resume-right {
  padding-left: 5rem !important;
}

@media (max-width: 767px) {
  .resume-left {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .resume-right {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
}
.resume .resume-title {
  font-size: 2rem;
  font-weight: 700;
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.resume .resume-itrem {
  padding: 0 0 0.75rem 1.5rem;
  margin-top: -2px;
  border-left: 2px solid #8a49a8;
  position: relative;
}

.resume .resume-itrem .resume-title {
  line-height: 1rem;
  font-size: 0.9rem;
  background: #5234795d;
  padding: 0.5rem 1rem;
  display: inline-block;
  font-weight: 600;
  margin-bottom: 0.75rem;
}

.resume .resume-itrem ul {
  padding-left: 1.25rem;
  text-align: justify;
}

.resume .resume-itrem ul li {
  padding-bottom: 0.75rem;
  list-style: none;
}

.resume .resume-itrem:last-child {
  padding-bottom: 0;
}

.resume .resume-itrem::before {
  content: "";
  position: absolute;
  width: 1rem;
  height: 1rem;
  border-radius: 3rem;
  left: -0.5rem;
  top: 0;
  background: #fff;
  border: 2px solid #8a49a8;
}

.like-itrem {
  padding-top: 0.75rem !important;
  font-size: 1.1rem !important;
  font-family: sans-serif !important;
}

.like-btn {
  background-color: #934cce5e !important;
  border-color: #934cce5e !important;
  padding: 0.25rem 0.98rem !important;
  border-radius: 5px !important;
  line-height: 1.4 !important;
  transition: 0.3s ease !important;
}

.like-btn:hover {
  transform: translateY(-2px) !important;
  background-color: #a24dd386 !important;
  border-color: #a24dd386 !important;
}

.animate-like {
  animation-name: likeAnimation;
  animation-fill-mode: forwards;
  animation-duration: 0.85s;
}
@keyframes likeAnimation {
  0% {
    transform: scale(1.5);
  }
  100% {
    transform: scale(1);
  }
}

.fork-btn {
  font-size: 1.1rem !important;
  padding-top: 0.75rem !important;
}

.fork-btn-inner {
  line-height: 1.4rem !important;
  background-color: #220429 !important;
  padding: 0.25rem 1.1rem !important;
  vertical-align: middle !important;
  text-align: center !important;
}

.fork-btn-inner:hover {
  transform: scale(1.05);
  transition: 0.5s;
}
.fork-btn-inner::after {
  display: none !important;
}
